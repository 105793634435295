import React from 'react';
import { Link } from 'react-router-dom';
import { clsx } from 'clsx';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'secondary' | 'outline';
  size?: 'sm' | 'md' | 'lg';
  className?: string;
}

interface ButtonLinkProps {
  to: string;
  variant?: 'primary' | 'secondary' | 'outline';
  size?: 'sm' | 'md' | 'lg';
  className?: string;
  children: React.ReactNode;
}

const baseStyles = {
  primary: 'bg-gray-900 text-white hover:bg-gray-800 shadow-sm',
  secondary: 'bg-gray-100 text-gray-900 hover:bg-gray-200',
  outline: 'border-2 border-gray-900 text-gray-900 hover:bg-gray-50'
};

const sizeStyles = {
  sm: 'px-3 py-1.5 text-sm',
  md: 'px-4 py-2',
  lg: 'px-6 py-3 text-lg'
};

export function Button({ 
  variant = 'primary', 
  size = 'md',
  className, 
  ...props 
}: ButtonProps) {
  return (
    <button
      className={clsx(
        'font-medium rounded-full transition-colors inline-flex items-center justify-center',
        baseStyles[variant],
        sizeStyles[size],
        className
      )}
      {...props}
    />
  );
}

export function ButtonLink({ 
  to, 
  variant = 'primary', 
  size = 'md',
  className, 
  children 
}: ButtonLinkProps) {
  return (
    <Link
      to={to}
      className={clsx(
        'font-medium rounded-full transition-colors inline-flex items-center justify-center',
        baseStyles[variant],
        sizeStyles[size],
        className
      )}
    >
      {children}
    </Link>
  );
}