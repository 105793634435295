import { useState, useCallback } from 'react';
import { addDays, startOfDay, endOfDay, startOfWeek, endOfWeek, 
         startOfMonth, endOfMonth } from 'date-fns';

export function useEventFilters() {
  const [filters, setFilters] = useState({
    city: '',
    date: '',
    price: '',
    cuisine: ''
  });

  const getDateRange = useCallback((dateFilter: string) => {
    const today = new Date();
    
    switch (dateFilter) {
      case 'today':
        return {
          start: startOfDay(today),
          end: endOfDay(today)
        };
      case 'tomorrow':
        return {
          start: startOfDay(addDays(today, 1)),
          end: endOfDay(addDays(today, 1))
        };
      case 'this-week':
        return {
          start: startOfWeek(today),
          end: endOfWeek(today)
        };
      case 'this-month':
        return {
          start: startOfMonth(today),
          end: endOfMonth(today)
        };
      default:
        return null;
    }
  }, []);

  const updateFilter = useCallback((key: keyof typeof filters, value: string) => {
    setFilters(prev => ({ ...prev, [key]: value }));
  }, []);

  return {
    filters,
    updateFilter,
    getDateRange
  };
} 