import React from 'react';
import { ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';

export function HeroSection() {
  const profiles = [
    'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    'https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'
  ];

  return (
    <section className="relative min-h-screen flex items-center">
      <div className="absolute inset-0">
        <img
          src="https://images.unsplash.com/photo-1552566626-52f8b828add9?ixlib=rb-1.2.1&auto=format&fit=crop&w=2700&q=80"
          alt="Luxury dining experience"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black/75" />
      </div>
      
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-32">
        <div className="max-w-3xl">
          <span className="text-white text-lg font-medium mb-4 block">
            Canada's Most Exclusive Dining Experience
          </span>
          <h1 className="text-5xl md:text-7xl font-bold text-white mb-6 leading-tight">
            Experience Every Dish on the Menu
          </h1>
          <p className="text-xl md:text-2xl text-white mb-12 leading-relaxed">
            Join intimate dining events where you'll taste every dish, meet amazing people, 
            and experience Canada's finest restaurants like never before.
          </p>
          <div className="flex flex-col sm:flex-row gap-4">
            <Link 
              to="/events" 
              className="group bg-white/20 backdrop-blur-sm text-white border-2 border-white/20 px-8 py-4 rounded-full text-lg font-semibold hover:bg-white/30 transition-colors inline-flex items-center justify-center"
            >
              View Upcoming Events
              <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
            </Link>
            <Link 
              to="/partner" 
              className="bg-white/20 backdrop-blur-sm text-white border-2 border-white/20 px-8 py-4 rounded-full text-lg font-semibold hover:bg-white/30 transition-colors inline-flex items-center justify-center"
            >
              Partner With Us
            </Link>
          </div>
          <div className="mt-12 flex items-center gap-8">
            <div className="flex -space-x-4">
              {profiles.map((profile, i) => (
                <img
                  key={i}
                  src={profile}
                  alt="Member"
                  className="w-10 h-10 rounded-full border-2 border-white object-cover"
                />
              ))}
            </div>
            <div className="text-white text-sm">
              Join 500+ food enthusiasts already experiencing Canada's best restaurants
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}