import { useState, useEffect } from 'react';
import { account } from '../lib/appwrite';
import { DATABASE_ID } from '../lib/appwrite';
import type { User } from '../types/database';

export function useAuth() {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    checkAuth();
  }, []);

  async function checkAuth() {
    try {
      const session = await account.getSession('current');
      if (session) {
        const accountDetails = await account.get();
        setUser({
          id: accountDetails.$id,
          email: accountDetails.email,
          name: accountDetails.name,
          role: 'user',
          phone: accountDetails.phone || undefined,
          city: accountDetails.prefs?.city
        });
      }
    } catch (error) {
      console.error('Auth check failed:', error);
      setUser(null);
    } finally {
      setLoading(false);
    }
  }

  const signInWithGoogle = async () => {
    try {
      const currentOrigin = window.location.origin;
      await account.createOAuth2Session(
        'google',
        `${currentOrigin}/profile`,
        `${currentOrigin}/login`
      );
    } catch (error) {
      console.error('Google sign in error:', error);
      throw error;
    }
  };

  const signInWithEmail = async (email: string, password: string) => {
    try {
      await account.createEmailSession(email, password);
      await checkAuth();
    } catch (error) {
      console.error('Email sign in error:', error);
      throw error;
    }
  };

  const signOut = async () => {
    try {
      await account.deleteSession('current');
      setUser(null);
    } catch (error) {
      console.error('Sign out error:', error);
      throw error;
    }
  };

  return {
    user,
    loading,
    error,
    signInWithGoogle,
    signInWithEmail,
    signOut,
    checkAuth
  };
}