import React from 'react';
import { Link } from 'react-router-dom';
import { MapPin, Calendar, Users } from 'lucide-react';
import { format } from 'date-fns';
import type { Event } from '../../types/database';

interface EventCardProps {
  event: Event;
  compact?: boolean;
}

export function EventCard({ event, compact = false }: EventCardProps) {
  return (
    <Link 
      to={`/events/${event.$id}`}
      className={`block bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow ${
        compact ? 'p-4' : 'overflow-hidden'
      }`}
    >
      <div className={compact ? 'flex gap-4' : ''}>
        <img
          src={event.imageUrl}
          alt={event.title}
          className={compact 
            ? "w-24 h-24 object-cover rounded-lg flex-shrink-0"
            : "w-full aspect-[16/9] object-cover"
          }
        />
        
        <div className={compact ? 'flex-1' : 'p-4'}>
          <h3 className={`font-semibold text-gray-900 ${compact ? 'text-base' : 'text-xl mb-2'}`}>
            {event.title}
          </h3>
          
          <div className="space-y-2">
            <div className="flex items-center text-gray-600 text-sm">
              <Calendar className="h-4 w-4 mr-2 flex-shrink-0" />
              <span>{format(new Date(event.date), 'MMMM d, yyyy')} at {event.time}</span>
            </div>
            
            <div className="flex items-center text-gray-600 text-sm">
              <MapPin className="h-4 w-4 mr-2 flex-shrink-0" />
              <span>{event.city}</span>
            </div>
          </div>

          {!compact && (
            <div className="mt-4 flex items-center justify-between">
              <div className="text-purple-600 font-semibold">
                ${event.price}
              </div>
              {event.capacity - event.registered > 0 ? (
                <span className="text-sm text-gray-600">
                  {event.capacity - event.registered} spots left
                </span>
              ) : (
                <span className="text-sm text-red-600 font-medium">
                  Sold Out
                </span>
              )}
            </div>
          )}
        </div>
      </div>
    </Link>
  );
}