import React from 'react';
import { Bell, Star, Clock } from 'lucide-react';
import { FeatureCard } from '../components/host/FeatureCard';
import { WhyJoinCard } from '../components/host/WhyJoinCard';
import { PartnerForm } from '../components/host/PartnerForm';

const features = [
  {
    icon: Bell,
    title: 'Exclusive Events',
    description: 'Host unique dining experiences that showcase your entire menu'
  },
  {
    icon: Clock,
    title: 'Flexible Scheduling',
    description: 'Choose event dates and times that work best for your restaurant'
  },
  {
    icon: Star,
    title: 'New Revenue Stream',
    description: 'Generate additional revenue through premium dining experiences'
  }
];

const benefits = [
  { text: 'Access to our network of food enthusiasts' },
  { text: 'Full-service event management and promotion' },
  { text: 'Premium pricing for complete menu experiences' }
];

export function BecomeHostPage() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      <div className="relative py-24">
        <div className="absolute inset-0 bg-gradient-to-b from-gray-50/50 to-white pointer-events-none" />
        
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto text-center mb-16">
            <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6">
              Partner With Us to Create Unforgettable Dining Experiences
            </h1>
            <p className="text-xl text-gray-600">
              Join Canada's premier platform for exclusive dining events and showcase 
              your restaurant to passionate food enthusiasts.
            </p>
          </div>

          <div className="grid lg:grid-cols-2 gap-16">
            <div className="space-y-12">
              <img
                src="https://images.unsplash.com/photo-1414235077428-338989a2e8c0?ixlib=rb-1.2.1&auto=format&fit=crop&w=2700&q=80"
                alt="Fine dining experience"
                className="w-full aspect-[16/9] object-cover rounded-2xl shadow-xl"
              />

              <div className="grid md:grid-cols-3 gap-8">
                {features.map((feature) => (
                  <FeatureCard key={feature.title} {...feature} />
                ))}
              </div>

              <WhyJoinCard benefits={benefits} />
            </div>

            <div>
              <div className="sticky top-8">
                <PartnerForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}