import { useState, useEffect } from 'react';
import { eventService } from '../services/database';
import type { Event } from '../types/database';

export function useRelatedEvents(city: string | undefined, currentEventId: string | undefined) {
  const [relatedEvents, setRelatedEvents] = useState<Event[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    async function fetchRelatedEvents() {
      if (!city || !currentEventId) {
        setRelatedEvents([]);
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        setError(null);

        // Fetch events in the same city
        const events = await eventService.list({ city });
        
        // Filter out the current event and limit to 3 related events
        const filtered = events
          .filter(event => event.$id !== currentEventId)
          .slice(0, 3);

        setRelatedEvents(filtered);
      } catch (err) {
        console.error('Failed to fetch related events:', err);
        setError('Failed to load related events');
        setRelatedEvents([]);
      } finally {
        setLoading(false);
      }
    }

    fetchRelatedEvents();
  }, [city, currentEventId]);

  return { relatedEvents, loading, error };
} 