import { Query } from 'appwrite';

// Event queries
export const publishedEventsQuery = [
  Query.equal('status', 'published'),
  Query.orderDesc('date')
];

export const cityEventsQuery = (city: string) => [
  Query.equal('status', 'published'),
  Query.equal('city', city),
  Query.orderDesc('date')
];

// Restaurant queries
export const approvedRestaurantsQuery = [
  Query.equal('status', 'approved'),
  Query.orderDesc('rating')
];

export const cityRestaurantsQuery = (city: string) => [
  Query.equal('status', 'approved'),
  Query.equal('city', city),
  Query.orderDesc('rating')
];

export const cuisineRestaurantsQuery = (cuisine: string) => [
  Query.equal('cuisine', cuisine)
];

// User queries
export const userBookingsQuery = (userId: string) => [
  Query.equal('userId', userId),
  Query.orderDesc('createdAt')
];