import { useState, useEffect } from 'react';
import { restaurantService } from '../services/database';
import type { Restaurant } from '../types/database';

export function useRestaurants(city?: string, cuisine?: string) {
  const [restaurants, setRestaurants] = useState<Restaurant[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    async function fetchRestaurants() {
      try {
        setLoading(true);
        setError(null);
        const data = await restaurantService.list({ city, cuisine });
        setRestaurants(data);
      } catch (err: any) {
        console.error('Failed to fetch restaurants:', err);
        setError(err.message || 'Failed to load restaurants');
      } finally {
        setLoading(false);
      }
    }

    fetchRestaurants();
  }, [city, cuisine]);

  return { restaurants, loading, error };
}