import React from 'react';

export function PressPage() {
  const pressReleases = [
    {
      date: 'March 15, 2024',
      title: 'Try Everything Expands to Four Major Canadian Cities',
      excerpt: 'Platform now connects food enthusiasts with unique dining experiences in Vancouver, Calgary, Toronto, and Montreal'
    },
    {
      date: 'March 1, 2024',
      title: 'Try Everything Partners with Top Canadian Restaurants',
      excerpt: 'Leading restaurants across Canada join platform to offer unique dining experiences'
    },
    {
      date: 'February 15, 2024',
      title: 'Try Everything Raises Series A Funding',
      excerpt: 'Investment will fuel expansion to new cities and enhance dining experience platform'
    }
  ];

  const mediaKit = [
    {
      title: 'Brand Assets',
      description: 'Download our logo, brand guidelines, and high-resolution images'
    },
    {
      title: 'Press Releases',
      description: 'Access our archive of press releases and company announcements'
    },
    {
      title: 'Media Coverage',
      description: 'View recent articles and features about Try Everything'
    }
  ];

  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h1 className="text-4xl font-bold text-gray-900 mb-8">Press & Media</h1>

      <div className="mb-12">
        <h2 className="text-2xl font-semibold text-gray-900 mb-6">Latest News</h2>
        <div className="space-y-12">
          {pressReleases.map((release, index) => (
            <div key={index} className="border-b border-gray-200 pb-8">
              <div className="text-sm text-gray-500 mb-2">{release.date}</div>
              <h3 className="text-2xl font-semibold text-gray-900 mb-4">{release.title}</h3>
              <p className="text-gray-600">{release.excerpt}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="mb-12">
        <h2 className="text-2xl font-semibold text-gray-900 mb-6">Media Kit</h2>
        <div className="grid md:grid-cols-3 gap-6">
          {mediaKit.map((item, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
              <h3 className="text-lg font-semibold text-gray-900 mb-2">{item.title}</h3>
              <p className="text-gray-600 mb-4">{item.description}</p>
              <button className="text-purple-600 hover:text-purple-700 font-medium">
                Download →
              </button>
            </div>
          ))}
        </div>
      </div>

      <div className="bg-gray-50 p-8 rounded-lg">
        <h2 className="text-2xl font-semibold text-gray-900 mb-4">Media Contact</h2>
        <p className="text-gray-600 mb-4">
          For press inquiries, please contact our media relations team:
        </p>
        <div className="space-y-2">
          <p className="text-gray-600">
            Email:{' '}
            <a href="mailto:press@tryeverything.ca" className="text-purple-600 hover:text-purple-700">
              press@tryeverything.ca
            </a>
          </p>
          <p className="text-gray-600">Phone: +1 (888) 555-0123</p>
        </div>
      </div>
    </div>
  );
}