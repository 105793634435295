import React from 'react';
import { ArrowRight } from 'lucide-react';

interface BenefitItem {
  text: string;
}

interface WhyJoinCardProps {
  benefits: BenefitItem[];
}

export function WhyJoinCard({ benefits }: WhyJoinCardProps) {
  return (
    <div className="bg-gradient-to-br from-purple-50 to-indigo-50 p-10 rounded-2xl border border-purple-100 shadow-sm">
      <h3 className="text-2xl font-semibold text-purple-900 mb-8">Why Join?</h3>
      <ul className="space-y-6">
        {benefits.map((benefit, index) => (
          <li key={index} className="flex items-center gap-4 text-purple-800">
            <div className="bg-white p-2 rounded-xl shadow-sm">
              <ArrowRight className="h-5 w-5 text-purple-600" />
            </div>
            <span className="text-lg font-medium">{benefit.text}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}