import { useState, useEffect } from 'react';
import { eventService } from '../services/database';
import type { Event } from '../types/database';
import { Query } from 'appwrite';

interface EventFilters {
  city?: string;
  date?: string;
  featured?: boolean;
}

export function useEvents(filters: EventFilters) {
  const [events, setEvents] = useState<Event[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    let mounted = true;

    async function fetchEvents() {
      try {
        setLoading(true);
        setError(null);
        
        const queries = [Query.equal('status', 'published')];
        
        if (filters.city) {
          queries.push(Query.equal('city', filters.city));
        }
        
        if (filters.date) {
          const today = new Date();
          queries.push(Query.greaterThanEqual('date', today.toISOString().split('T')[0]));
        }

        if (filters.featured) {
          queries.push(Query.limit(20));
        }

        queries.push(Query.orderAsc('date'));
        
        const data = await eventService.list({ queries });
        
        if (mounted) {
          setEvents(data);
        }
      } catch (err) {
        console.error('Failed to fetch events:', err);
        if (mounted) {
          setError(err instanceof Error ? err.message : 'Failed to load events');
        }
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    }

    fetchEvents();

    return () => {
      mounted = false;
    };
  }, [filters.city, filters.date, filters.featured]);

  return { events, loading, error };
}