import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { Icon } from 'leaflet';
import type { Restaurant } from '../../types';
import { MiniRestaurantCard } from '../ui/MiniRestaurantCard';

const customIcon = new Icon({
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

interface RestaurantMapProps {
  restaurants: Restaurant[];
  selectedRestaurant?: Restaurant;
  onRestaurantSelect?: (restaurant: Restaurant | undefined) => void;
}

export function RestaurantMap({ restaurants, selectedRestaurant, onRestaurantSelect }: RestaurantMapProps) {
  const center = { lat: 51.0447, lng: -114.0719 }; // Calgary coordinates

  return (
    <MapContainer
      center={[center.lat, center.lng]}
      zoom={12}
      style={{ width: '100%', height: '100%' }}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {restaurants.map(restaurant => (
        <Marker
          key={restaurant.id}
          position={[
            restaurant.coordinates?.lat || center.lat,
            restaurant.coordinates?.lng || center.lng
          ]}
          icon={customIcon}
          eventHandlers={{
            click: () => onRestaurantSelect?.(restaurant)
          }}
        >
          <Popup>
            <MiniRestaurantCard restaurant={restaurant} />
          </Popup>
        </Marker>
      ))}
    </MapContainer>
  );
}