/**
 * Type-safe environment variable getter with validation
 * @param key The environment variable key
 * @param required Whether the environment variable is required
 * @returns The environment variable value
 * @throws Error if a required environment variable is missing
 */
export function getEnvVar(key: string, required = true): string {
  const value = import.meta.env[key];
  
  if (!value && required) {
    throw new Error(`Missing required environment variable: ${key}`);
  }
  
  return value || '';
}

/**
 * Validates that all required environment variables are present
 * @param requiredVars Array of required environment variable keys
 * @throws Error if any required variables are missing
 */
export function validateEnvVars(requiredVars: string[]): void {
  const missing = requiredVars.filter(key => !import.meta.env[key]);
  
  if (missing.length > 0) {
    throw new Error(`Missing required environment variables: ${missing.join(', ')}`);
  }
}