import React, { useEffect, useState } from 'react';
import { useSearchParams, Navigate } from 'react-router-dom';
import { bookingService } from '../services/database';

export function CheckoutSuccessPage() {
  const [searchParams] = useSearchParams();
  const [error, setError] = useState<string | null>(null);
  const sessionId = searchParams.get('session_id');

  useEffect(() => {
    async function createBooking() {
      if (!sessionId) return;

      try {
        // Verify payment and get session details from your backend
        const response = await fetch('/.netlify/functions/verifyPayment', {
          method: 'POST',
          body: JSON.stringify({ sessionId }),
        });

        const { success, data } = await response.json();
        console.log('Payment verification response:', { success, data });

        if (success) {
          // Log the booking data before creation
          console.log('Creating booking with data:', {
            eventId: data.eventId,
            userId: data.userId,
            quantity: parseInt(data.quantity),
            totalAmount: data.amount / 100,
            status: 'confirmed',
            stripeSessionId: sessionId,
          });

          // Create booking in Appwrite
          await bookingService.create({
            eventId: data.eventId,
            userId: data.userId,
            quantity: parseInt(data.quantity),
            totalAmount: data.amount / 100,
            status: 'confirmed',
            stripeSessionId: sessionId,
          });

          window.location.href = '/profile/bookings';
        } else {
          setError('Payment verification failed');
        }
      } catch (error) {
        console.error('Error creating booking:', error);
        setError('Failed to process booking');
      }
    }

    createBooking();
  }, [sessionId]);

  if (!sessionId) {
    return <Navigate to="/events" replace />;
  }

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-purple-500"></div>
    </div>
  );
} 