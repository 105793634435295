import React from 'react';
import { HeroSection } from '../components/home/HeroSection';
import { FeaturedEvents } from '../components/home/FeaturedEvents';
import { HowItWorks } from '../components/home/HowItWorks';
import { CTASection } from '../components/home/CTASection';

export function HomePage() {
  return (
    <div>
      <HeroSection />
      <FeaturedEvents />
      <HowItWorks />
      <CTASection />
    </div>
  );
}