import { useState, useEffect } from 'react';
import { eventService } from '../services/database';
import type { Event } from '../types/database';

export function useEvent(id: string) {
  const [event, setEvent] = useState<Event | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    async function fetchEvent() {
      if (!id) {
        setLoading(false);
        setError('No event ID provided');
        return;
      }

      try {
        setLoading(true);
        setError(null);
        const data = await eventService.get(id);
        
        if (!data) {
          setError('Event not found');
          setEvent(null);
        } else {
          setEvent(data);
        }
      } catch (err) {
        console.error('Failed to fetch event:', err);
        setError('Failed to load event');
        setEvent(null);
      } finally {
        setLoading(false);
      }
    }

    fetchEvent();
  }, [id]);

  return { event, loading, error };
} 