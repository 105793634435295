import React, { useState } from 'react';
import { Button } from '../ui/Button';
import { Card } from '../ui/Card';

export function PartnerForm() {
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget;
    
    try {
      const formData = new FormData(form);
      const response = await fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams(formData as any).toString()
      });

      if (response.ok) {
        setSubmitted(true);
      }
    } catch (error) {
      console.error('Form submission error:', error);
    }
  };

  if (submitted) {
    return (
      <Card className="p-8 text-center">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">Thank you for your interest!</h2>
        <p className="text-gray-600">
          Our team will review your information and contact you within 2 business days.
        </p>
      </Card>
    );
  }

  return (
    <Card className="p-8 shadow-xl border border-gray-100 bg-gradient-to-b from-white to-gray-50/30">
      <form 
        name="partner"
        method="POST"
        data-netlify="true"
        netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
        className="space-y-6"
      >
        <input type="hidden" name="form-name" value="partner" />
        <div hidden>
          <input name="bot-field" />
        </div>

        <div>
          <label htmlFor="restaurantName" className="block text-lg font-medium text-gray-900 mb-2">
            Restaurant Name <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="restaurantName"
            name="restaurantName"
            required
            className="w-full rounded-xl border-gray-300 focus:border-purple-500 focus:ring-purple-500 text-lg py-3 shadow-sm"
          />
        </div>

        <div>
          <label htmlFor="email" className="block text-lg font-medium text-gray-900 mb-2">
            Business Email <span className="text-red-500">*</span>
          </label>
          <input
            type="email"
            id="email"
            name="email"
            required
            className="w-full rounded-xl border-gray-300 focus:border-purple-500 focus:ring-purple-500 text-lg py-3 shadow-sm"
            placeholder="you@restaurant.com"
          />
        </div>

        <div>
          <label htmlFor="phone" className="block text-lg font-medium text-gray-900 mb-2">
            Phone Number
          </label>
          <input
            type="tel"
            id="phone"
            name="phone"
            className="w-full rounded-xl border-gray-300 focus:border-purple-500 focus:ring-purple-500 text-lg py-3 shadow-sm"
            placeholder="(Optional)"
          />
        </div>

        <div>
          <label htmlFor="city" className="block text-lg font-medium text-gray-900 mb-2">
            City <span className="text-red-500">*</span>
          </label>
          <select
            id="city"
            name="city"
            required
            className="w-full rounded-xl border-gray-300 focus:border-purple-500 focus:ring-purple-500 text-lg py-3 shadow-sm"
          >
            <option value="">Select a city</option>
            <option value="vancouver">Vancouver, BC</option>
            <option value="calgary">Calgary, AB</option>
            <option value="toronto">Toronto, ON</option>
            <option value="montreal">Montreal, QC</option>
          </select>
        </div>

        <div>
          <label htmlFor="message" className="block text-lg font-medium text-gray-900 mb-2">
            Tell us about your restaurant
          </label>
          <textarea
            id="message"
            name="message"
            rows={4}
            className="w-full rounded-xl border-gray-300 focus:border-purple-500 focus:ring-purple-500 text-lg py-3 shadow-sm"
            placeholder="Type of cuisine, seating capacity, etc."
          />
        </div>

        <Button 
          type="submit" 
          className="w-full py-4 text-lg rounded-xl bg-gradient-to-r from-gray-800 to-gray-900 hover:from-gray-900 hover:to-black"
        >
          Submit Application
        </Button>

        <p className="text-sm text-gray-600 text-center">
          By submitting this form, you agree to be contacted about partnership opportunities.
        </p>
      </form>
    </Card>
  );
}