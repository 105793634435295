import React from 'react';

export function AboutPage() {
  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h1 className="text-4xl font-bold text-gray-900 mb-8">About Try Everything</h1>
      
      <div className="prose prose-lg">
        <p className="text-xl text-gray-600 mb-8">
          Try Everything is Canada's premier platform for discovering and experiencing the country's 
          vibrant food culture through unique, curated dining events.
        </p>

        <h2 className="text-2xl font-semibold text-gray-900 mt-12 mb-4">Our Mission</h2>
        <p className="text-gray-600 mb-8">
          We believe that the best way to experience a city's culture is through its food and people. 
          Our mission is to connect food enthusiasts with extraordinary dining experiences, creating 
          memorable moments around the table in Canada's most exciting restaurants.
        </p>

        <h2 className="text-2xl font-semibold text-gray-900 mt-12 mb-4">Our Story</h2>
        <p className="text-gray-600 mb-8">
          Founded in Calgary and now operating in Vancouver, Toronto, and Montreal, Try Everything 
          started with a simple idea: make it easy for people to experience everything a restaurant 
          has to offer. Today, we're proud to help thousands of Canadians discover new flavors, 
          meet fellow food lovers, and create unforgettable dining memories.
        </p>

        <h2 className="text-2xl font-semibold text-gray-900 mt-12 mb-4">How It Works</h2>
        <p className="text-gray-600 mb-8">
          We partner with exceptional restaurants to create unique events where guests can experience 
          the entire menu in one sitting. Our carefully curated experiences bring together passionate 
          food lovers in an intimate setting, fostering connections and creating memorable dining moments.
        </p>

        <h2 className="text-2xl font-semibold text-gray-900 mt-12 mb-4">Our Values</h2>
        <ul className="list-disc pl-6 text-gray-600 mb-8">
          <li className="mb-2">
            <strong>Community First:</strong> We believe in the power of food to bring people together
          </li>
          <li className="mb-2">
            <strong>Quality Experience:</strong> Every event is carefully curated to ensure excellence
          </li>
          <li className="mb-2">
            <strong>Local Focus:</strong> We celebrate and support local restaurants and food culture
          </li>
          <li>
            <strong>Inclusivity:</strong> We create welcoming spaces for all food enthusiasts
          </li>
        </ul>
      </div>
    </div>
  );
}