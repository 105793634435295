import React from 'react';
import { Calendar, MapPin, Users } from 'lucide-react';
import type { Event } from '../../types';
import { Link } from 'react-router-dom';

interface MiniEventCardProps {
  event: Event;
}

export function MiniEventCard({ event }: MiniEventCardProps) {
  return (
    <Link to={`/events/${event.id}`} className="block w-64">
      <div className="space-y-3">
        <img 
          src={event.imageUrl}
          alt={event.title}
          className="w-full h-32 object-cover rounded-lg"
        />
        <div className="space-y-2">
          <h3 className="font-semibold text-purple-900">{event.title}</h3>
          <div className="space-y-1">
            <div className="flex items-center text-purple-600 text-sm">
              <Calendar className="h-4 w-4 mr-2" />
              <span>{event.time}</span>
            </div>
            <div className="flex items-center text-purple-600 text-sm">
              <MapPin className="h-4 w-4 mr-2" />
              <span>{event.restaurant.name}</span>
            </div>
            <div className="flex items-center text-purple-600 text-sm">
              <Users className="h-4 w-4 mr-2" />
              <span>{event.registered}/{event.capacity} spots</span>
            </div>
          </div>
          <div className="flex items-center justify-between pt-2 border-t border-purple-100">
            <span className="font-semibold text-purple-700">${event.price}</span>
            <span className="text-sm text-purple-600">
              {event.capacity - event.registered} left
            </span>
          </div>
        </div>
      </div>
    </Link>
  );
}