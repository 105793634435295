import React from 'react';
import { Search, MapPin, UtensilsCrossed } from 'lucide-react';
import { SUPPORTED_CITIES } from '../../config/cities';

interface RestaurantFiltersProps {
  selectedCity: string;
  onCityChange: (city: string) => void;
  selectedCuisine: string;
  onCuisineChange: (cuisine: string) => void;
}

const CUISINES = [
  'All Cuisines',
  'Fine Dining',
  'French',
  'Japanese',
  'Thai',
  'Canadian',
  'Contemporary',
  'Greek'
];

export function RestaurantFilters({
  selectedCity,
  onCityChange,
  selectedCuisine,
  onCuisineChange
}: RestaurantFiltersProps) {
  return (
    <div className="bg-white p-6 rounded-lg shadow-sm">
      <div className="flex flex-col md:flex-row gap-4">
        <div className="flex-1">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <input
              type="text"
              placeholder="Search restaurants..."
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
        </div>
        
        <div className="flex gap-4">
          <div className="relative">
            <MapPin className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <select
              value={selectedCity}
              onChange={(e) => onCityChange(e.target.value)}
              className="pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
            >
              <option value="">All Cities</option>
              {SUPPORTED_CITIES.map((city) => (
                <option key={city.id} value={city.id}>
                  {city.name}, {city.province}
                </option>
              ))}
            </select>
          </div>

          <div className="relative">
            <UtensilsCrossed className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <select
              value={selectedCuisine}
              onChange={(e) => onCuisineChange(e.target.value)}
              className="pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
            >
              {CUISINES.map((cuisine) => (
                <option key={cuisine} value={cuisine === 'All Cuisines' ? '' : cuisine}>
                  {cuisine}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
}