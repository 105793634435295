import { databases, DATABASE_ID, COLLECTIONS, handleAppwriteError } from './appwrite';
import { publishedEventsQuery, cityEventsQuery } from './queries';
import type { Event } from '../types/database';

export async function getEvents(city?: string) {
  try {
    const queries = city ? cityEventsQuery(city) : publishedEventsQuery;

    const response = await databases.listDocuments(
      DATABASE_ID,
      COLLECTIONS.EVENTS,
      queries
    );

    return response.documents as Event[];
  } catch (error) {
    return handleAppwriteError(error);
  }
}

export async function getEvent(id: string) {
  try {
    const event = await databases.getDocument(
      DATABASE_ID,
      COLLECTIONS.EVENTS,
      id
    );
    return event as Event;
  } catch (error) {
    return handleAppwriteError(error);
  }
}