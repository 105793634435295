import React, { useState, useRef } from 'react';
import { useAuth } from '../hooks/useAuth';
import { Card } from '../components/ui/Card';
import { Button } from '../components/ui/Button';
import { PencilIcon } from 'lucide-react';
import { account, storage, ID } from '../lib/appwrite';
import { getEnvVar } from '../utils/env';
import { appwriteConfig } from '../config/appwrite';

export function ProfilePage() {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [phoneNumber, setPhoneNumber] = useState(user?.phone || '');
  const [showPasswordChange, setShowPasswordChange] = useState(false);
  const [passwordData, setPasswordData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });

  if (!user) {
    return null;
  }

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      setLoading(true);
      setMessage('Uploading image...');

      const response = await storage.createFile(
        appwriteConfig.profileBucketId,
        ID.unique(),
        file
      );

      const fileUrl = storage.getFileView(
        appwriteConfig.profileBucketId,
        response.$id
      );

      await account.updatePrefs({
        avatar: fileUrl
      });

      setMessage('Profile picture updated successfully');
      
      window.location.reload();
    } catch (error) {
      console.error('Failed to upload image:', error);
      setMessage(error instanceof Error ? error.message : 'Failed to update profile picture');
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordChange = async (e: React.FormEvent) => {
    e.preventDefault();
    if (passwordData.newPassword !== passwordData.confirmPassword) {
      setMessage('New passwords do not match');
      return;
    }

    try {
      setLoading(true);
      await account.updatePassword(passwordData.newPassword, passwordData.currentPassword);
      setMessage('Password updated successfully');
      setShowPasswordChange(false);
    } catch (error) {
      console.error('Failed to update password:', error);
      setMessage('Failed to update password');
    } finally {
      setLoading(false);
    }
  };

  const handlePhoneVerification = async () => {
    try {
      setLoading(true);
      // Send verification SMS
      await account.createPhoneVerification();
      setMessage('Verification code sent to your phone');
    } catch (error) {
      console.error('Failed to send verification:', error);
      setMessage('Failed to send verification code');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-8 space-y-8">
      {message && (
        <div className="bg-blue-50 text-blue-700 p-4 rounded-md">
          {message}
        </div>
      )}

      <div className="flex items-center space-x-6">
        <div className="relative">
          <img
            src={user.avatar || `https://ui-avatars.com/api/?name=${encodeURIComponent(user.name)}`}
            alt={user.name}
            className="h-24 w-24 rounded-full object-cover"
          />
          <Button
            onClick={() => fileInputRef.current?.click()}
            className="absolute bottom-0 right-0 rounded-full p-2"
            disabled={loading}
          >
            <PencilIcon className="h-4 w-4" />
          </Button>
          <input
            type="file"
            ref={fileInputRef}
            className="hidden"
            accept="image/*"
            onChange={handleImageUpload}
          />
        </div>
        <div>
          <h1 className="text-3xl font-bold text-gray-900">{user.name}</h1>
          <p className="text-gray-600">{user.email}</p>
        </div>
      </div>

      <div className="grid md:grid-cols-2 gap-8">
        <Card className="p-6">
          <h2 className="text-xl font-semibold mb-4">Profile Information</h2>
          <form className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Name
              </label>
              <input
                type="text"
                defaultValue={user.name}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <input
                type="email"
                defaultValue={user.email}
                disabled
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm bg-gray-50"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Phone Number
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <input
                  type="tel"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  className="flex-1 rounded-l-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                />
                <Button
                  type="button"
                  onClick={handlePhoneVerification}
                  disabled={loading || !phoneNumber}
                  className="ml-3"
                >
                  Verify
                </Button>
              </div>
            </div>

            <Button type="submit" disabled={loading}>
              Save Changes
            </Button>
          </form>
        </Card>

        <Card className="p-6">
          <h2 className="text-xl font-semibold mb-4">Security</h2>
          <div className="space-y-4">
            <Button
              onClick={() => setShowPasswordChange(!showPasswordChange)}
              variant="outline"
              className="w-full"
            >
              Change Password
            </Button>

            {showPasswordChange && (
              <form onSubmit={handlePasswordChange} className="space-y-4">
                <input
                  type="password"
                  placeholder="Current Password"
                  value={passwordData.currentPassword}
                  onChange={(e) => setPasswordData(prev => ({
                    ...prev,
                    currentPassword: e.target.value
                  }))}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
                <input
                  type="password"
                  placeholder="New Password"
                  value={passwordData.newPassword}
                  onChange={(e) => setPasswordData(prev => ({
                    ...prev,
                    newPassword: e.target.value
                  }))}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
                <input
                  type="password"
                  placeholder="Confirm New Password"
                  value={passwordData.confirmPassword}
                  onChange={(e) => setPasswordData(prev => ({
                    ...prev,
                    confirmPassword: e.target.value
                  }))}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
                <Button type="submit" disabled={loading}>
                  Update Password
                </Button>
              </form>
            )}

            <Button
              onClick={() => account.createVerification(window.location.href)}
              variant="outline"
              className="w-full"
              disabled={user.emailVerification}
            >
              {user.emailVerification ? 'Email Verified' : 'Verify Email'}
            </Button>
          </div>
        </Card>
      </div>

      <div className="grid md:grid-cols-3 gap-8">
        <Card className="p-6">
          <h2 className="text-xl font-semibold mb-4">Upcoming Events</h2>
          <p className="text-gray-600">No upcoming events</p>
        </Card>

        <Card className="p-6">
          <h2 className="text-xl font-semibold mb-4">Past Events</h2>
          <p className="text-gray-600">No past events</p>
        </Card>

        <Card className="p-6">
          <h2 className="text-xl font-semibold mb-4">Saved Events</h2>
          <p className="text-gray-600">No saved events</p>
        </Card>
      </div>
    </div>
  );
}