import { useState, useEffect } from 'react';
import { restaurantService } from '../services/database';
import type { Restaurant } from '../types/database';

export function useRestaurant(id: string | undefined, useDocumentId = true) {
  const [restaurant, setRestaurant] = useState<Restaurant | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    async function fetchRestaurant() {
      if (!id) {
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        setError(null);
        const response = await restaurantService.get(id, useDocumentId);
        setRestaurant(response);
      } catch (err) {
        console.error('Failed to fetch restaurant:', err);
        setError('Failed to load restaurant details');
      } finally {
        setLoading(false);
      }
    }

    fetchRestaurant();
  }, [id, useDocumentId]);

  return { restaurant, loading, error };
} 