import React from 'react';

export function CookiesPage() {
  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h1 className="text-4xl font-bold text-gray-900 mb-8">Cookie Policy</h1>
      
      <div className="prose prose-lg">
        <p className="text-gray-600 mb-8">
          Last updated: March 15, 2024
        </p>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">What Are Cookies</h2>
          <p className="text-gray-600">
            Cookies are small text files that are stored on your computer or mobile device when 
            you visit our website.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">How We Use Cookies</h2>
          <p className="text-gray-600">
            We use cookies to understand how you use our website and to improve your experience. 
            This includes remembering your preferences and keeping you signed in.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Managing Cookies</h2>
          <p className="text-gray-600">
            You can control and manage cookies in your browser settings. Please note that 
            removing or blocking cookies may impact your user experience.
          </p>
        </section>
      </div>
    </div>
  );
}