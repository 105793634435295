import React from 'react';
import { Link } from 'react-router-dom';
import { Calendar, MapPin, Users, ArrowRight } from 'lucide-react';
import { format } from 'date-fns';
import { useEvents } from '../../hooks/useEvents';

export function FeaturedEvents() {
  const { events, loading } = useEvents({ 
    city: '', 
    date: '', 
    featured: true 
  });

  if (loading) {
    return (
      <section className="py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold text-gray-900 mb-4">Featured Experiences</h2>
            <p className="text-xl text-gray-600">Discover unique dining events across Canada</p>
          </div>
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
            {[...Array(4)].map((_, i) => (
              <div key={i} className="animate-pulse">
                <div className="h-4 bg-gray-200 rounded w-1/4 mb-4"></div>
                <div className="aspect-[16/9] bg-gray-200 rounded-xl mb-4"></div>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  }

  // Group events by city
  const eventsByCity = events.reduce((acc, event) => {
    if (!acc[event.city]) {
      acc[event.city] = [];
    }
    acc[event.city].push(event);
    return acc;
  }, {} as Record<string, typeof events>);

  // Get one event from each city
  const featuredEvents = Object.entries(eventsByCity).map(([city, cityEvents]) => {
    // Get the event with the earliest date
    return cityEvents.reduce((earliest, current) => {
      return new Date(current.date) < new Date(earliest.date) ? current : earliest;
    }, cityEvents[0]);
  });

  return (
    <section className="py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-gray-900 mb-4">Featured Experiences</h2>
          <p className="text-xl text-gray-600">Discover unique dining events across Canada</p>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
          {featuredEvents.map((event) => (
            <div key={event.$id} className="space-y-4">
              <h3 className="text-xl font-bold text-gray-900 border-b border-gray-200 pb-2">
                {event.city}
              </h3>
              <Link to={`/events/${event.$id}`} className="group block">
                <div className="relative overflow-hidden rounded-xl shadow-lg">
                  <div className="relative h-80">
                    <img 
                      src={event.imageUrl}
                      alt={event.title}
                      className="absolute inset-0 w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-500"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/30 to-transparent" />
                    <div className="absolute bottom-0 left-0 right-0 p-6 text-white">
                      <h4 className="text-lg font-bold mb-2">{event.title}</h4>
                      <div className="space-y-2 text-sm">
                        <div className="flex items-center">
                          <Calendar className="h-4 w-4 mr-2" />
                          <span>{format(new Date(event.date), 'MMMM d, yyyy')} at {event.time}</span>
                        </div>
                        <div className="flex items-center">
                          <MapPin className="h-4 w-4 mr-2" />
                          <span>{event.restaurant?.name}</span>
                        </div>
                        <div className="flex items-center">
                          <Users className="h-4 w-4 mr-2" />
                          <span>{event.capacity - event.registered} spots left</span>
                        </div>
                      </div>
                      <div className="mt-4 flex items-center justify-between">
                        <span className="text-2xl font-bold">${event.price}</span>
                        <span className="text-sm">Book Now →</span>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}