export const SUPPORTED_CITIES = [
  {
    id: 'vancouver',
    name: 'Vancouver',
    province: 'BC',
    coordinates: { lat: 49.2827, lng: -123.1207 }
  },
  {
    id: 'calgary',
    name: 'Calgary', 
    province: 'AB',
    coordinates: { lat: 51.0447, lng: -114.0719 }
  },
  {
    id: 'toronto',
    name: 'Toronto',
    province: 'ON',
    coordinates: { lat: 43.6532, lng: -79.3832 }
  },
  {
    id: 'montreal',
    name: 'Montreal',
    province: 'QC',
    coordinates: { lat: 45.5017, lng: -73.5673 }
  }
] as const;

export type SupportedCity = typeof SUPPORTED_CITIES[number]['id'];

export function getCityName(cityId: SupportedCity): string {
  return SUPPORTED_CITIES.find(city => city.id === cityId)?.name || '';
}

export function getCityCoordinates(cityId: SupportedCity) {
  return SUPPORTED_CITIES.find(city => city.id === cityId)?.coordinates;
}