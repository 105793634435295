import React from 'react';
import { Star, MapPin, DollarSign } from 'lucide-react';
import type { Restaurant } from '../../types';
import { Link } from 'react-router-dom';

interface MiniRestaurantCardProps {
  restaurant: Restaurant;
}

export function MiniRestaurantCard({ restaurant }: MiniRestaurantCardProps) {
  return (
    <Link to={`/restaurants/${restaurant.id}`} className="block w-64">
      <div className="space-y-3">
        {restaurant.imageUrl && (
          <img 
            src={restaurant.imageUrl}
            alt={restaurant.name}
            className="w-full h-32 object-cover rounded-lg"
          />
        )}
        <div className="space-y-2">
          <h3 className="font-semibold text-purple-900">{restaurant.name}</h3>
          <div className="space-y-1">
            <div className="flex items-center text-purple-600 text-sm">
              <Star className="h-4 w-4 mr-2" />
              <span>{restaurant.rating} Rating</span>
            </div>
            <div className="flex items-center text-purple-600 text-sm">
              <MapPin className="h-4 w-4 mr-2" />
              <span>{restaurant.address}</span>
            </div>
            <div className="flex items-center text-purple-600 text-sm">
              <DollarSign className="h-4 w-4 mr-2" />
              <span>{restaurant.priceRange}</span>
            </div>
          </div>
          <div className="pt-2 border-t border-purple-100">
            <span className="text-sm text-purple-600">{restaurant.cuisine}</span>
          </div>
        </div>
      </div>
    </Link>
  );
}