import React from 'react';
import { useLocation } from 'react-router-dom';
import { ContactForm } from '../components/forms/ContactForm';

export function ContactPage() {
  const location = useLocation();

  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">Contact Us</h1>
        <p className="text-xl text-gray-600">
          We'd love to hear from you. Send us a message and we'll respond as soon as possible.
        </p>
      </div>

      <div className="grid md:grid-cols-2 gap-12">
        <div>
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Get in Touch</h2>
          <ContactForm returnTo={location.pathname} />
        </div>

        <div>
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Other Ways to Reach Us</h2>
          <div className="space-y-6">
            <div>
              <h3 className="font-medium text-gray-900">Email</h3>
              <p className="text-gray-600">tryeverything.mgmt@gmail.com</p>
            </div>
            <div>
              <h3 className="font-medium text-gray-900">Office</h3>
              <p className="text-gray-600">
                123 9th Avenue SE<br />
                Calgary, AB T2G 0P3<br />
                Canada
              </p>
            </div>
            <div>
              <h3 className="font-medium text-gray-900">Hours</h3>
              <p className="text-gray-600">
                Monday - Friday: 9:00 AM - 6:00 PM MDT<br />
                Saturday - Sunday: Closed
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}