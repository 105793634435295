import React from 'react';
import { LoginForm } from '../components/auth/LoginForm';
import { useSearchParams, useNavigate } from 'react-router-dom';

export function LoginPage() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const returnTo = searchParams.get('returnTo') || '/profile';

  const handleLoginSuccess = () => {
    navigate(returnTo);
  };

  return <LoginForm onLoginSuccess={handleLoginSuccess} />;
}