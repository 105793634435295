export const stripeService = {
  createCheckoutSession: async (data: {
    eventId: string;
    userId: string;
    quantity: number;
    totalAmount: number;
  }) => {
    try {
      // Call Netlify function directly
      const response = await fetch('/.netlify/functions/createStripeCheckout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      });

      if (!response.ok) {
        throw new Error('Failed to create checkout session');
      }

      const result = await response.json();
      return result as { sessionId: string; url: string };
    } catch (error) {
      console.error('Stripe checkout error:', error);
      throw error;
    }
  }
}; 