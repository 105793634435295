import React, { useState } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { EventCard } from './EventCard';
import { Button } from '../ui/Button';
import type { Event } from '../../types';

interface CityEventsSliderProps {
  cityName: string;
  events: Event[];
  expanded: boolean;
  onExpand: () => void;
}

export function CityEventsSlider({ cityName, events, expanded, onExpand }: CityEventsSliderProps) {
  const [scrollPosition, setScrollPosition] = useState(0);

  const scroll = (direction: 'left' | 'right') => {
    const container = document.getElementById(`slider-${cityName}`);
    if (!container) return;

    const scrollAmount = direction === 'left' ? -400 : 400;
    const newPosition = scrollPosition + scrollAmount;
    
    container.scrollTo({
      left: newPosition,
      behavior: 'smooth'
    });
    
    setScrollPosition(newPosition);
  };

  if (!events.length) return null;

  return (
    <div className="mb-12">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold text-gray-900">{cityName}</h2>
        <Button variant="outline" onClick={onExpand}>
          {expanded ? 'Show Less' : 'View All'}
        </Button>
      </div>

      {expanded ? (
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
          {events.map((event) => (
            <EventCard key={event.id} event={event} />
          ))}
        </div>
      ) : (
        <div className="relative">
          <div
            className="flex overflow-x-hidden scroll-smooth gap-6 relative"
            id={`slider-${cityName}`}
          >
            {events.map((event) => (
              <div key={event.id} className="flex-none w-[400px]">
                <EventCard event={event} />
              </div>
            ))}
          </div>
          
          <button
            onClick={() => scroll('left')}
            className="absolute left-0 top-1/2 -translate-y-1/2 bg-white/80 backdrop-blur-sm p-2 rounded-full shadow-lg hover:bg-white transition-colors"
          >
            <ChevronLeft className="h-6 w-6" />
          </button>
          
          <button
            onClick={() => scroll('right')}
            className="absolute right-0 top-1/2 -translate-y-1/2 bg-white/80 backdrop-blur-sm p-2 rounded-full shadow-lg hover:bg-white transition-colors"
          >
            <ChevronRight className="h-6 w-6" />
          </button>
        </div>
      )}
    </div>
  );
}