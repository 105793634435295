export const CALGARY_NEIGHBORHOODS = [
  'Beltline',
  'Mission',
  'Inglewood',
  'Bridgeland',
  'Kensington',
  'Downtown',
  'East Village',
  'Chinatown',
  'Victoria Park',
  'Eau Claire'
] as const;

export const CALGARY_CUISINES = [
  'Fine Dining',
  'Italian',
  'Japanese',
  'Chinese',
  'Indian',
  'Mexican',
  'Thai',
  'Vietnamese',
  'Steakhouse',
  'Fusion'
] as const;