import React, { useState } from 'react';
import { MapIcon, List } from 'lucide-react';
import { RestaurantFilters } from '../components/restaurants/RestaurantFilters';
import { RestaurantGrid } from '../components/restaurants/RestaurantGrid';
import { RestaurantMap } from '../components/map/RestaurantMap';
import { Button } from '../components/ui/Button';
import { useRestaurants } from '../hooks/useRestaurants';
import type { Restaurant } from '../types/database';

export function RestaurantsPage() {
  const [viewMode, setViewMode] = useState<'list' | 'map'>('list');
  const [selectedRestaurant, setSelectedRestaurant] = useState<Restaurant | null>(null);
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedCuisine, setSelectedCuisine] = useState('');
  const { restaurants, loading, error } = useRestaurants(selectedCity, selectedCuisine);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-purple-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 text-center">
        <div className="bg-red-50 text-red-700 p-4 rounded-md">
          {error}
        </div>
        <Button 
          onClick={() => window.location.reload()} 
          className="mt-4"
        >
          Try Again
        </Button>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 space-y-8">
      <div className="text-center mb-8">
        <h1 className="text-4xl font-bold text-gray-900">
          Partner Restaurants Across Canada
        </h1>
        <p className="text-xl text-gray-600 mt-2">
          Discover exceptional restaurants that host Try Everything events
        </p>
      </div>

      <div className="flex justify-between items-center">
        <RestaurantFilters
          selectedCity={selectedCity}
          onCityChange={setSelectedCity}
          selectedCuisine={selectedCuisine}
          onCuisineChange={setSelectedCuisine}
        />
        <div className="flex gap-2">
          <Button
            variant={viewMode === 'list' ? 'primary' : 'secondary'}
            onClick={() => setViewMode('list')}
            className="flex items-center gap-2"
          >
            <List className="h-5 w-5" />
            List
          </Button>
          <Button
            variant={viewMode === 'map' ? 'primary' : 'secondary'}
            onClick={() => setViewMode('map')}
            className="flex items-center gap-2"
          >
            <MapIcon className="h-5 w-5" />
            Map
          </Button>
        </div>
      </div>

      {viewMode === 'list' ? (
        <RestaurantGrid restaurants={restaurants} />
      ) : (
        <div className="h-[600px] rounded-lg overflow-hidden">
          <RestaurantMap
            restaurants={restaurants}
            selectedRestaurant={selectedRestaurant}
            onRestaurantSelect={setSelectedRestaurant}
          />
        </div>
      )}
    </div>
  );
}