import React from 'react';
import { Button } from '../components/ui/Button';
import { SUPPORTED_CITIES } from '../config/cities';

export function CareersPage() {
  const openings = [
    {
      title: 'City Manager',
      department: 'Operations',
      locations: ['Vancouver', 'Toronto'],
      type: 'Full-time',
      description: 'Lead our expansion and operations in a major Canadian city.'
    },
    {
      title: 'Senior Software Engineer',
      department: 'Engineering',
      locations: ['Remote'],
      type: 'Full-time',
      description: 'Build the future of dining experiences through technology.'
    },
    {
      title: 'Restaurant Partnership Manager',
      department: 'Partnerships',
      locations: ['Montreal', 'Calgary'],
      type: 'Full-time',
      description: 'Grow our network of partner restaurants and curate exceptional dining experiences.'
    },
    {
      title: 'Marketing Coordinator',
      department: 'Marketing',
      locations: ['Toronto'],
      type: 'Full-time',
      description: 'Create compelling content and grow our community of food enthusiasts.'
    }
  ];

  return (
    <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">Join Our Team</h1>
        <p className="text-xl text-gray-600">
          Help us connect people through extraordinary dining experiences across Canada
        </p>
      </div>

      <div className="mb-12">
        <h2 className="text-2xl font-semibold text-gray-900 mb-4">Why Try Everything?</h2>
        <div className="grid md:grid-cols-3 gap-6">
          <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
            <h3 className="text-lg font-semibold text-gray-900 mb-2">Impact</h3>
            <p className="text-gray-600">
              Shape the future of dining experiences and support local restaurants across Canada.
            </p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
            <h3 className="text-lg font-semibold text-gray-900 mb-2">Growth</h3>
            <p className="text-gray-600">
              Join a rapidly expanding startup with opportunities for advancement.
            </p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
            <h3 className="text-lg font-semibold text-gray-900 mb-2">Culture</h3>
            <p className="text-gray-600">
              Work with passionate people who love food, technology, and community.
            </p>
          </div>
        </div>
      </div>

      <h2 className="text-2xl font-semibold text-gray-900 mb-6">Open Positions</h2>
      <div className="grid gap-6">
        {openings.map((job, index) => (
          <div key={index} className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
            <div className="flex justify-between items-start">
              <div>
                <h3 className="text-xl font-semibold text-gray-900">{job.title}</h3>
                <p className="text-gray-600 mt-1">{job.department}</p>
              </div>
              <Button>Apply Now</Button>
            </div>
            <p className="text-gray-600 mt-4">{job.description}</p>
            <div className="flex gap-4 mt-4">
              <span className="text-sm text-gray-600">
                {job.locations.join(' / ')}
              </span>
              <span className="text-sm text-gray-600">{job.type}</span>
            </div>
          </div>
        ))}
      </div>

      <div className="mt-12 text-center">
        <p className="text-gray-600">
          Don't see a position that matches your skills? Email us at{' '}
          <a href="mailto:careers@tryeverything.ca" className="text-purple-600 hover:text-purple-700">
            careers@tryeverything.ca
          </a>
        </p>
      </div>
    </div>
  );
}