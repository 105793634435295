import React, { useState } from 'react';
import { MapIcon, List } from 'lucide-react';
import { EventFilters } from '../components/events/EventFilters';
import { EventMap } from '../components/map/EventMap';
import { CityEventsSlider } from '../components/events/CityEventsSlider';
import { Button } from '../components/ui/Button';
import { useEvents } from '../hooks/useEvents';
import { useEventFilters } from '../hooks/useEventFilters';
import { SUPPORTED_CITIES } from '../config/cities';

export function EventsPage() {
  const [viewMode, setViewMode] = useState<'list' | 'map'>('list');
  const [expandedCity, setExpandedCity] = useState<string | null>(null);
  const { filters, updateFilter } = useEventFilters();
  const { events, loading, error } = useEvents(filters);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-purple-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 text-center">
        <div className="bg-red-50 text-red-700 p-4 rounded-md">
          {error}
        </div>
        <Button 
          onClick={() => window.location.reload()} 
          className="mt-4"
        >
          Try Again
        </Button>
      </div>
    );
  }

  // Group events by city
  const eventsByCity = SUPPORTED_CITIES.reduce((acc, city) => {
    acc[city.id] = events.filter(event => 
      event.city.toLowerCase() === city.name.toLowerCase()
    );
    return acc;
  }, {} as Record<string, Event[]>);

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 space-y-8">
      <div className="text-center mb-8">
        <h1 className="text-4xl font-bold text-gray-900">
          Food Events Across Canada
        </h1>
        <p className="text-xl text-gray-600 mt-2">
          Discover unique dining experiences in your city
        </p>
      </div>

      <div className="flex justify-between items-center">
        <EventFilters 
          filters={filters}
          onFilterChange={updateFilter}
        />
        <div className="flex gap-2">
          <Button
            variant={viewMode === 'list' ? 'primary' : 'secondary'}
            onClick={() => setViewMode('list')}
            className="flex items-center gap-2"
          >
            <List className="h-5 w-5" />
            List
          </Button>
          <Button
            variant={viewMode === 'map' ? 'primary' : 'secondary'}
            onClick={() => setViewMode('map')}
            className="flex items-center gap-2"
          >
            <MapIcon className="h-5 w-5" />
            Map
          </Button>
        </div>
      </div>

      {viewMode === 'list' ? (
        <div className="space-y-8">
          {SUPPORTED_CITIES.map((city) => (
            <CityEventsSlider
              key={city.id}
              cityName={city.name}
              events={eventsByCity[city.id] || []}
              expanded={expandedCity === city.id}
              onExpand={() => setExpandedCity(expandedCity === city.id ? null : city.id)}
            />
          ))}
        </div>
      ) : (
        <div className="h-[600px] rounded-lg overflow-hidden">
          <EventMap
            events={events}
            selectedEvent={undefined}
            onEventSelect={() => {}}
          />
        </div>
      )}
    </div>
  );
}