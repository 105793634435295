import { databases, DATABASE_ID, COLLECTIONS, ID, Query } from '../lib/appwrite';
import type { Restaurant, Event, Booking } from '../types/database';

// Restaurant Services
export const restaurantService = {
  // List all restaurants with optional filters
  list: async (filters?: { city?: string; cuisine?: string }) => {
    const queries = [Query.equal('status', ['approved'])];
    
    if (filters?.city) {
      queries.push(Query.equal('city', filters.city));
    }
    
    if (filters?.cuisine) {
      queries.push(Query.equal('cuisine', filters.cuisine));
    }

    queries.push(Query.orderDesc('rating'));

    // Simple debug log without endpoint info
    console.log('Fetching restaurants with:', {
      databaseId: DATABASE_ID,
      collectionId: COLLECTIONS.RESTAURANTS,
      queries: queries.map(q => q.toString())
    });

    const response = await databases.listDocuments(
      DATABASE_ID,
      COLLECTIONS.RESTAURANTS,
      queries
    );

    return response.documents as Restaurant[];
  },

  // Get a single restaurant - can fetch by document ID or restaurantId
  get: async (id: string, useDocumentId = false) => {
    if (!id) {
      console.warn('No restaurant ID provided');
      return null;
    }

    try {
      if (useDocumentId) {
        // Fetch directly by document ID
        const response = await databases.getDocument(
          DATABASE_ID,
          COLLECTIONS.RESTAURANTS,
          id
        );
        return response as Restaurant;
      } else {
        // Query by restaurantId field
        const response = await databases.listDocuments(
          DATABASE_ID,
          COLLECTIONS.RESTAURANTS,
          [Query.equal('restaurantId', id)]
        );

        if (response.documents.length === 0) {
          console.warn('No restaurant found with restaurantId:', id);
          return null;
        }

        return response.documents[0] as Restaurant;
      }
    } catch (error) {
      console.error('Failed to fetch restaurant:', {
        error,
        id,
        useDocumentId,
        collectionId: COLLECTIONS.RESTAURANTS
      });
      throw error;
    }
  }
};

// Event Services
export const eventService = {
  // List all events with optional filters
  list: async (options?: { 
    city?: string; 
    date?: string;
    queries?: any[] // Add queries parameter
  }) => {
    let queries = [Query.equal('status', 'published')];
    
    if (options?.queries) {
      queries = [...queries, ...options.queries];
    } else {
      if (options?.city) {
        queries.push(Query.equal('city', options.city));
      }
      
      if (options?.date) {
        const today = new Date();
        queries.push(Query.greaterThanEqual('date', today.toISOString().split('T')[0]));
      }

      queries.push(Query.orderAsc('date'));
    }

    const response = await databases.listDocuments(
      DATABASE_ID,
      COLLECTIONS.EVENTS,
      queries
    );

    return response.documents as Event[];
  },

  // Get a single event
  get: async (id: string) => {
    try {
      const event = await databases.getDocument(
        DATABASE_ID,
        COLLECTIONS.EVENTS,
        id
      );

      console.log('Event fetch:', {
        event,
        restaurantId: event.restaurantId,
        eventId: id
      });

      // Get restaurant using restaurantId field query
      if (event.restaurantId) {
        const restaurantResponse = await databases.listDocuments(
          DATABASE_ID,
          COLLECTIONS.RESTAURANTS,
          [Query.equal('restaurantId', event.restaurantId)]
        );
        
        if (restaurantResponse.documents.length > 0) {
          console.log('Found associated restaurant:', restaurantResponse.documents[0]);
        }
      }

      return event as Event;
    } catch (error) {
      console.error('Event fetch error:', error);
      return null; // Return null instead of throwing
    }
  },

  listByRestaurant: async (restaurantId: string) => {
    console.log('listByRestaurant called with:', restaurantId);
    
    try {
      const queries = [
        Query.equal('restaurantId', restaurantId), // Use the restaurantId field directly
        Query.equal('status', 'published'),
        Query.greaterThanEqual('date', new Date().toISOString().split('T')[0]),
        Query.orderAsc('date')
      ];

      console.log('Fetching events with queries:', queries.map(q => q.toString()));

      const response = await databases.listDocuments(
        DATABASE_ID,
        COLLECTIONS.EVENTS,
        queries
      );

      console.log('Restaurant events response:', {
        total: response.total,
        documents: response.documents
      });

      return response.documents as Event[];
    } catch (error) {
      console.error('Failed to fetch restaurant events:', {
        error,
        restaurantId,
        collectionId: COLLECTIONS.EVENTS
      });
      throw error;
    }
  },
};

// Booking Services
export const bookingService = {
  // Create a booking
  create: async (data: Omit<Booking, 'id' | '$id' | '$createdAt' | '$updatedAt'>) => {
    const response = await databases.createDocument(
      DATABASE_ID,
      COLLECTIONS.BOOKINGS,
      ID.unique(),
      data
    );
    return response as Booking;
  },

  // List user's bookings
  listUserBookings: async (userId: string) => {
    const response = await databases.listDocuments(
      DATABASE_ID,
      COLLECTIONS.BOOKINGS,
      [
        Query.equal('userId', userId),
        Query.orderDesc('$createdAt')
      ]
    );
    return response.documents as Booking[];
  },

  // Update booking status
  updateStatus: async (id: string, status: Booking['status']) => {
    const response = await databases.updateDocument(
      DATABASE_ID,
      COLLECTIONS.BOOKINGS,
      id,
      { status }
    );
    return response as Booking;
  }
}; 