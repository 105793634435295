import { databases, DATABASE_ID, COLLECTIONS } from './appwrite';
import { Query, ID } from 'appwrite';
import type { Booking } from '../types/database';

export async function createBooking(booking: Omit<Booking, 'id' | 'createdAt' | 'updatedAt'>) {
  try {
    const now = new Date().toISOString();
    const response = await databases.createDocument(
      DATABASE_ID,
      COLLECTIONS.BOOKINGS,
      ID.unique(),
      {
        ...booking,
        createdAt: now,
        updatedAt: now
      }
    );
    return response as Booking;
  } catch (error) {
    console.error('Create booking error:', error);
    throw error;
  }
}

export async function getUserBookings(userId: string) {
  try {
    const response = await databases.listDocuments(
      DATABASE_ID,
      COLLECTIONS.BOOKINGS,
      [
        Query.equal('userId', userId),
        Query.orderDesc('createdAt')
      ]
    );
    return response.documents as Booking[];
  } catch (error) {
    console.error('Get user bookings error:', error);
    throw error;
  }
}

export async function updateBooking(id: string, booking: Partial<Booking>) {
  try {
    const response = await databases.updateDocument(
      DATABASE_ID,
      COLLECTIONS.BOOKINGS,
      id,
      {
        ...booking,
        updatedAt: new Date().toISOString()
      }
    );
    return response as Booking;
  } catch (error) {
    console.error('Update booking error:', error);
    throw error;
  }
}