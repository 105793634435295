import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { Card } from '../../components/ui/Card';
import { Button } from '../../components/ui/Button';
import { Calendar, MapPin, Users } from 'lucide-react';
import { format } from 'date-fns';
import { getUserBookings } from '../../lib/bookings';
import { getEvent } from '../../lib/events';
import type { Booking, Event } from '../../types/database';

interface BookingWithEvent extends Booking {
  event?: Event;
}

export function BookingsPage() {
  const { user } = useAuth();
  const [bookings, setBookings] = useState<BookingWithEvent[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    async function fetchBookings() {
      if (!user) return;

      try {
        setLoading(true);
        setError(null);
        
        const userBookings = await getUserBookings(user.id);
        
        // Fetch event details for each booking
        const bookingsWithEvents = await Promise.all(
          userBookings.map(async (booking) => {
            try {
              const event = await getEvent(booking.eventId);
              return { ...booking, event };
            } catch (err) {
              console.error(`Failed to fetch event ${booking.eventId}:`, err);
              return booking;
            }
          })
        );

        setBookings(bookingsWithEvents);
      } catch (err) {
        console.error('Failed to fetch bookings:', err);
        setError('Failed to load your bookings. Please try again later.');
      } finally {
        setLoading(false);
      }
    }

    fetchBookings();
  }, [user]);

  if (!user) return null;

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-purple-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-12 text-center">
        <div className="bg-red-50 text-red-700 p-4 rounded-md">
          {error}
        </div>
        <Button 
          onClick={() => window.location.reload()} 
          className="mt-4"
        >
          Try Again
        </Button>
      </div>
    );
  }

  const upcomingBookings = bookings.filter(
    booking => booking.event && new Date(booking.event.date) >= new Date()
  );

  const pastBookings = bookings.filter(
    booking => booking.event && new Date(booking.event.date) < new Date()
  );

  return (
    <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <h1 className="text-3xl font-bold text-gray-900 mb-8">Your Bookings</h1>

      <div className="space-y-8">
        <div>
          <h2 className="text-xl font-semibold text-gray-900 mb-4">Upcoming Events</h2>
          <div className="grid md:grid-cols-2 gap-6">
            {upcomingBookings.length > 0 ? (
              upcomingBookings.map((booking) => (
                <Card key={booking.id} className="overflow-hidden">
                  {booking.event && (
                    <>
                      <img
                        src={booking.event.imageUrl}
                        alt={booking.event.title}
                        className="w-full h-48 object-cover"
                      />
                      <div className="p-6">
                        <h3 className="text-lg font-semibold text-gray-900 mb-2">
                          {booking.event.title}
                        </h3>
                        <div className="space-y-2 text-sm text-gray-600">
                          <div className="flex items-center">
                            <Calendar className="h-4 w-4 mr-2" />
                            <span>
                              {format(new Date(booking.event.date), 'MMMM d, yyyy')} at {booking.event.time}
                            </span>
                          </div>
                          <div className="flex items-center">
                            <MapPin className="h-4 w-4 mr-2" />
                            <span>{booking.event.location}</span>
                          </div>
                          <div className="flex items-center">
                            <Users className="h-4 w-4 mr-2" />
                            <span>{booking.quantity} {booking.quantity === 1 ? 'ticket' : 'tickets'}</span>
                          </div>
                        </div>
                        {booking.status === 'confirmed' && (
                          <Button
                            variant="outline"
                            className="mt-4 w-full"
                            onClick={() => {/* Handle cancellation */}}
                          >
                            Cancel Booking
                          </Button>
                        )}
                      </div>
                    </>
                  )}
                </Card>
              ))
            ) : (
              <p className="text-gray-600 col-span-2">No upcoming events</p>
            )}
          </div>
        </div>

        <div>
          <h2 className="text-xl font-semibold text-gray-900 mb-4">Past Events</h2>
          <div className="grid md:grid-cols-2 gap-6">
            {pastBookings.length > 0 ? (
              pastBookings.map((booking) => (
                <Card key={booking.id} className="overflow-hidden opacity-75">
                  {booking.event && (
                    <>
                      <img
                        src={booking.event.imageUrl}
                        alt={booking.event.title}
                        className="w-full h-48 object-cover"
                      />
                      <div className="p-6">
                        <h3 className="text-lg font-semibold text-gray-900 mb-2">
                          {booking.event.title}
                        </h3>
                        <div className="space-y-2 text-sm text-gray-600">
                          <div className="flex items-center">
                            <Calendar className="h-4 w-4 mr-2" />
                            <span>
                              {format(new Date(booking.event.date), 'MMMM d, yyyy')} at {booking.event.time}
                            </span>
                          </div>
                          <div className="flex items-center">
                            <MapPin className="h-4 w-4 mr-2" />
                            <span>{booking.event.location}</span>
                          </div>
                          <div className="flex items-center">
                            <Users className="h-4 w-4 mr-2" />
                            <span>{booking.quantity} {booking.quantity === 1 ? 'ticket' : 'tickets'}</span>
                          </div>
                        </div>
                        <Button
                          variant="outline"
                          className="mt-4 w-full"
                          onClick={() => {/* Handle review */}}
                        >
                          Write Review
                        </Button>
                      </div>
                    </>
                  )}
                </Card>
              ))
            ) : (
              <p className="text-gray-600 col-span-2">No past events</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}