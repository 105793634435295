import { Client, Account, Databases, Storage, Query, AppwriteException, ID } from 'appwrite';
import { getEnvVar } from '../utils/env';

// Initialize Appwrite client
export const client = new Client();

// Configure the client
client
    .setEndpoint(getEnvVar('VITE_APPWRITE_ENDPOINT'))
    .setProject(getEnvVar('VITE_APPWRITE_PROJECT_ID'));

// Initialize services
export const account = new Account(client);
export const databases = new Databases(client);
export const storage = new Storage(client);

// Export utilities
export { Query, ID };

// Database and collection IDs
export const DATABASE_ID = getEnvVar('VITE_APPWRITE_DATABASE_ID');

export const COLLECTIONS = {
    USERS: import.meta.env.VITE_APPWRITE_USERS_COLLECTION_ID,
    EVENTS: import.meta.env.VITE_APPWRITE_EVENTS_COLLECTION_ID,
    RESTAURANTS: import.meta.env.VITE_APPWRITE_RESTAURANTS_COLLECTION_ID,
    BOOKINGS: import.meta.env.VITE_APPWRITE_BOOKINGS_COLLECTION_ID,
    REVIEWS: import.meta.env.VITE_APPWRITE_REVIEWS_COLLECTION_ID
};

// Add console log to verify collection IDs
console.log('Collection IDs:', COLLECTIONS);

// OAuth Configuration
export function getOAuthSuccessURL() {
  const returnTo = new URLSearchParams(window.location.search).get('returnTo');
  return returnTo || '/profile';
}

export const OAUTH_FAILURE_URL = '/login';

// Error handling helper
export function handleAppwriteError(error: any) {
    console.error('Appwrite error:', error);

    if (error?.code === 401) {
        throw new Error('Authentication failed. Please check your Appwrite project ID and endpoint.');
    }

    if (error?.code === 404) {
        if (error.type === 'collection_not_found') {
            throw new Error(`Collection not found. Please verify your collection ID: ${error?.message}`);
        }
        if (error.type === 'database_not_found') {
            throw new Error(`Database not found. Please verify your database ID: ${error?.message}`);
        }
    }

    throw new Error(error?.message || 'An unexpected error occurred');
}

// Add validation to ensure environment variables are set
validateAppwriteConfig();

function validateAppwriteConfig() {
    const requiredVars = [
        'VITE_APPWRITE_ENDPOINT',
        'VITE_APPWRITE_PROJECT_ID',
        'VITE_APPWRITE_DATABASE_ID',
        'VITE_APPWRITE_RESTAURANTS_COLLECTION_ID'
    ];

    const config: Record<string, string> = {};

    for (const varName of requiredVars) {
        const value = import.meta.env[varName];
        if (!value) {
            throw new Error(`Missing required environment variable: ${varName}`);
        }
        config[varName] = value;
    }

    // Log full configuration details
    console.log('Full Appwrite Configuration:', {
        ...config,
        restaurantCollectionId: {
            env: import.meta.env.VITE_APPWRITE_RESTAURANTS_COLLECTION_ID,
            config: config.VITE_APPWRITE_RESTAURANTS_COLLECTION_ID,
            collections: COLLECTIONS.RESTAURANTS,
            length: COLLECTIONS.RESTAURANTS.length
        }
    });

    // Verify client initialization
    console.log('Appwrite Client Status:', {
        isInitialized: Boolean(client),
        hasProject: Boolean(client.config.project),
        projectId: client.config.project
    });

    // Test connection
    testAppwriteConnection();

    // Validate restaurant collection ID format
    const restaurantCollectionId = config.VITE_APPWRITE_RESTAURANTS_COLLECTION_ID;
    if (restaurantCollectionId !== '67552855001fd0560b61') {
        console.warn('Warning: Restaurant collection ID mismatch', {
            current: restaurantCollectionId,
            expected: '67552855001fd0560b61'
        });
    }
}

async function testAppwriteConnection() {
    try {
        const response = await databases.listDocuments(
            DATABASE_ID,
            COLLECTIONS.RESTAURANTS,
            [Query.limit(1)]
        );
        console.log('Appwrite Connection Test:', {
            success: true,
            documentsFound: response.total,
            databaseId: DATABASE_ID,
            collectionId: COLLECTIONS.RESTAURANTS
        });
    } catch (error) {
        const appwriteError = error as AppwriteException;
        console.error('Appwrite Connection Test Failed:', {
            error: appwriteError,
            type: appwriteError.type,
            code: appwriteError.code,
            message: appwriteError.message,
            response: appwriteError.response
        });
    }
}

// Add this function to test restaurant fetch
async function testRestaurantFetch(restaurantId: string) {
  try {
    const response = await databases.getDocument(
      DATABASE_ID,
      COLLECTIONS.RESTAURANTS,
      restaurantId
    );
    console.log('Restaurant fetch test:', {
      success: true,
      restaurant: response,
      restaurantId,
      collectionId: COLLECTIONS.RESTAURANTS
    });
  } catch (error) {
    console.error('Restaurant fetch failed:', {
      error,
      restaurantId,
      collectionId: COLLECTIONS.RESTAURANTS
    });
  }
}

// Initialize and validate right away
validateAppwriteConfig();